import { AccentBtn } from '@playbooks/interface/buttons';
import { Tag, TagActions, TagBody, TagImg } from '@playbooks/interface/tags';

export const SearchTag = ({ result = {}, onRemove }) => (
	<Tag size='xxs' spacing=''>
		<TagImg size='w-4 h-4' src={result.thumbnail} />
		<TagBody>{result.name}</TagBody>
		<TagActions>
			<AccentBtn size='xxs' icon='xmark' onClick={() => onRemove(result)} />
		</TagActions>
	</Tag>
);
