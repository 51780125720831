import { useEffect, useMemo, useState } from 'react';

import { AccentBtn } from '@playbooks/interface/buttons';
import { Drop, DropBtn, DropItem, DropList, DropMenu } from '@playbooks/interface/drops';
import { Span } from '@playbooks/interface/html';
import { FarIcon } from '@playbooks/interface/icons';
import { InputAppend } from '@playbooks/interface/input-groups';
import { SearchTag } from 'components/search/search-tag';
import { useToast } from 'contexts';
import { SearchDivForm, SearchForm } from 'molecules/forms';
import { Skeleton } from 'molecules/skeletons';
import { AlgoliaService } from 'services';
import { capitalize, listBuilder } from 'utils';

const TableSearchDrop = ({ id, tableName, keyName, api, prevIcon, value, placeholder, onChange, tailwind }) => {
	const [options, setOptions] = useState([]);
	const [params, setParams] = useState({ pageSize: 1000, sortProp: 'name', sortValue: 'asc' });
	const [query, setQuery] = useState('');
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const toast = useToast();

	// Computed
	const client = new AlgoliaService({ tableName });

	const computedValue = value ? value[keyName] : value;

	const computedOptions = useMemo(() => {
		return options?.filter(v => v[keyName]?.toLowerCase().includes(query?.toLowerCase()));
	}, [options, query]);

	const isActive = options?.includes(value);

	// Hooks
	useEffect(() => {
		if (open) searchDb();
	}, [open, api, query]);

	// Methods
	const searchDb = async () => {
		try {
			setLoading(true);
			const response = await client.queryIndex(query, { ...params, ...api });
			const sortedData = client.sortData(response.data, params);
			setOptions(sortedData);
		} catch (e) {
			toast.showError(e);
		} finally {
			setLoading(false);
		}
	};

	const onClose = () => setOpen(false);

	const onOpen = () => setOpen(true);

	const onToggle = () => setOpen(!open);

	const onClick = option => {
		setOpen(false);
		setQuery('');
		onChange(option);
	};

	// Render
	return (
		<Drop open={open} setOpen={onClose} {...tailwind?.drop}>
			{value ? (
				<SearchDivForm prevIcon={prevIcon} tailwind={tailwind.search}>
					<SearchTag result={value} onRemove={() => onClick(null)} />
				</SearchDivForm>
			) : (
				<SearchForm
					id={id}
					delay={100}
					prevIcon={prevIcon}
					query={query}
					setQuery={setQuery}
					placeholder={placeholder}
					onFocus={onOpen}
					onClear={() => onClick(null)}
					elements={{
						inputAppend: (
							<InputAppend>
								<AccentBtn size='xs' icon='chevron-down' fontSize='text-xs' onClick={onToggle} />
							</InputAppend>
						),
					}}
					tailwind={tailwind?.search}
				/>
			)}
			<DropMenu open={open} location='left-0' maxHeight='min-h-[200px] max-h-[300px]'>
				{loading ? (
					<DropList display='flex-col' space='space-y-4' spacing='p-4'>
						{listBuilder(3).map((v, i) => (
							<Skeleton key={i} type='filter' />
						))}
					</DropList>
				) : (
					<DropList space='space-y-4'>
						{computedOptions?.map((option, i) => (
							<DropItem key={i}>
								<DropBtn
									prevIcon={{ src: option.thumbnail }}
									onClick={() => onClick(option)}
									span={{ display: 'flex-between', width: 'w-full' }}>
									<Span>{capitalize(option[keyName])}</Span>
									{computedValue === option[keyName] && <FarIcon icon='check' />}
								</DropBtn>
							</DropItem>
						))}
						{isActive && (
							<DropItem>
								<DropBtn
									nextIcon='xmark'
									onClick={() => onClick('')}
									span={{ display: 'flex-between', width: 'w-full' }}>
									Clear
								</DropBtn>
							</DropItem>
						)}
					</DropList>
				)}
			</DropMenu>
		</Drop>
	);
};

export { TableSearchDrop };
