import { useEffect, useState } from 'react';

import { Drop, DropBtn, DropItem, DropList, DropMenu, DropToggle } from '@playbooks/interface/drops';
import { capitalize } from 'utils';

const MultiSelectDrop = ({ id, values, placeholder, options, taskRunning, onChange, ...tailwind }) => {
	const [open, setOpen] = useState(false);

	// Hooks
	useEffect(() => {
		// log('options: ', options);
	}, [options]);

	// Methods
	const onClose = () => setOpen(false);

	const onToggle = () => setOpen(!open);

	const onClick = option => {
		setOpen(false);
		onChange(option);
	};

	const onClear = () => {
		setOpen(false);
		onChange([]);
	};

	// Render
	return (
		<Drop open={open} setOpen={onClose}>
			<DropToggle id={id} type='border' taskRunning={taskRunning} onClick={onToggle} {...tailwind}>
				{values.length > 0 ? `${values.length} Selected` : placeholder}
			</DropToggle>
			<DropMenu open={open} maxHeight='max-h-[300px]' width='w-full'>
				<DropList>
					{values.length > 0 && (
						<DropItem>
							<DropBtn nextIcon='trash' onClick={onClear} className='flex-between w-full'>
								Clear selections
							</DropBtn>
						</DropItem>
					)}
					{options?.map((option, i) => (
						<DropItem key={i}>
							<DropBtn
								nextIcon={values.includes(option) ? 'check' : ''}
								onClick={() => onClick(option)}
								className='flex-between w-full'>
								{capitalize(option)}
							</DropBtn>
						</DropItem>
					))}
				</DropList>
			</DropMenu>
		</Drop>
	);
};

export { MultiSelectDrop };
